<template>
  <div class='flex flex-col lg:flex-row justify-start lg:justify-between items-stretch gap-y-4 lg:gap-y-0 lg:gap-x-20 px-4 lg:px-0'>
    <hotel-and-tour-navigation class='flex-shrink-0' />
    <div class='flex-grow pt-16'>
      <welcome-title title='Tour Information' />
      <p>The 26<sup>th</sup> Congress of the Asian Pacific Society of Respirology (APSR 2022) Local Congress Committee provides a variety of Tour Programs where you can feel and experience Korean cultures first-hand.</p>
      
      <div class='half-day-tour-container'>   
        <h1 class='mt-8 text-2xl font-semibold uppercase'>Half-day tours</h1>
        <table class='w-full mt-4 mb-16'>
          <colgroup>
              <col width="60px">
              <col width="*">
              <col width="120px">
              <col width="120px">
          </colgroup>
          <thead class=''>
              <tr class='border'>
                <th class='py-2 uppercase text-gray-600 text-sm text-left pl-2' colspan="2">Course</th>
                <th class='py-2 uppercase text-gray-600 text-sm text-center'>Time</th>
                <th class='py-2 uppercase text-gray-600 text-sm text-right pr-2 leading-5'>Price per Person</th>
              </tr>
          </thead>
          <tbody>
              <tr class='border-t border-l border-r'>
                <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>HD-1</td>
                <td class='align-text-top py-2 px-2'>
                  <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Half-Day City Experience </h3>
                  <p class='text-sm'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to Bukchon Hanok Village → </span><span class='block lg:inline-block'>Samcheongdong → </span><span class='block lg:inline-block'>Insadong → </span><span class='block lg:inline-block'>Back to COEX</span></p>
                </td>
                <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>14:00 – 18:00</td>
                <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 53,000</td>
              </tr>
              <tr class='border-t border-l border-r'>
                <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>HD-2</td>
                <td class='align-text-top py-2 px-2'>
                  <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Taekwondo Activity</h3>
                  <p class='text-sm'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to Taekwondo Dojo → </span><span class='block lg:inline-block'>Taekwondo Experience → </span><span class='block lg:inline-block'>Back to COEX</span></p>
                </td>
                <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:30 – 13:30</td>
                <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 100,000</td>
              </tr>
              <tr class='border-t border-l border-r'>
                <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>HD-3</td>
                <td class='align-text-top py-2 px-2'>
                  <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>K-Pop Dance Experience</h3>
                  <p class='text-sm'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to REAL Kpop → </span><span class='block lg:inline-block'>K-Pop Dance Experience → </span><span class='block lg:inline-block'>Back to COEX</span></p>
                </td>
                <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:30 – 13:30</td>
                <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 100,000</td>
              </tr>
              <tr class='border-t border-l border-r'>
                <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>HD-4</td>
                <td class='align-text-top py-2 px-2'>
                  <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Night Tour: Han River Cruise (Wed-Sun only)</h3>
                  <p class='text-sm'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to Han River Park → </span><span class='block lg:inline-block'>Ride on Cruise → </span><span class='block lg:inline-block'>Back to COEX</span></p>
                </td>
                <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>18:00 – 22:00 </td>
                <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 94,000</td>
              </tr>
              <tr class='border-t border-l border-r border-b'>
                <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>HD-5</td>
                <td class='align-text-top py-2 px-2'>
                  <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Night Tour: Market Food Tour</h3>
                  <p class='text-sm'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to Gwangjang Market → </span><span class='block lg:inline-block'>Nogari Super → </span><span class='block lg:inline-block'>Back to COEX</span></p>
                </td>
                <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>16:00 – 20:00</td>
                <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 55,000</td>
              </tr>
          </tbody>
        </table>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            HD-1
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>City Experience</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image001.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image002.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Time</label>
                <div class='text-gray-600 font-normal'>14:00 – 18:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class="krw">KRW 53,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price Includes</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'><span class='block lg:inline-block'>Meet at COEX → </span><span class='block lg:inline-block'>Transfer to Bukchon Hanok Village → </span><span class='block lg:inline-block'>Samcheongdong → </span><span class='block lg:inline-block'>Insadong → </span><span class='block lg:inline-block'>Back to COEX</span></div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Description</label>
                <div class='text-gray-600 font-normal'>Bukchon Hanok Village is a densely populated hanok area in Seoul and located between the two palaces of the Joseon Dynasty. It is also called a museum in the city center because there are many historical, cultural properties, and folk materials.<br> Samcheong-dong is famous for being visited by many tourists. Insa-dong is also known as a popular shopping spot and called Mary's Alley among foreigners. There are a lot of antique shops.</div>
              </div>
            </div>
          </div>
        </div>
        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            HD-2
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>TAEKWONDO ACTIVITY</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image003.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image004.png" class='block rounded'>
            </div>                  
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:30 – 13:30</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 100,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Transfer to Taekwondo Dojo → Taekwondo Experience → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Contents</label>
                <div class='text-gray-600 font-normal'>Taekwondo, the most recognized form of Korean martial arts, is a sport that emphasizes a sense of morality, humility, and a respect for one's opponent. It has become a globally recognized sport where practitioners improve both their physical ability and mental strength.</div>
              </div>
            </div>
          </div>
        </div>              
        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            HD-3
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>K-POP DANCE EXPERIENCE</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image005.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image006.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:30 – 13:30</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 100,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, K-pop Experience Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Transfer to REAL K-Pop→ K-pop Dance Experience → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Contents</label>
                <div class='text-gray-600 font-normal'>K-pop is a genre of pop music originating from South Korea. The genre combines such Western pop music elements as hip-hop and rock with Korean traditions, and frequently involves dance routines and singing in Korean lyrics intermixed with some English.</div>
              </div>
            </div>
          </div>
        </div>
        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            HD-4
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>NIGHT TOUR: HAN RIVER CRUISE</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image007.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image008.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>Wed – Sun , 18:00 – 22:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 94,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Transfer to Han River Park → Han River Cruise Ride → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Contents</label>
                <div class='text-gray-600 font-normal'>Han River Ferry Cruise began operations on October 26, 1986, traversing the Han River as it runs through Seoul from east to west. Riders can see the beautiful Han River by day on the Story Cruise, or take in the city nightscape and colorful Banpo Rainbow Bridge on the Music Cruise.</div>
              </div>
            </div>
          </div>
        </div>
        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            HD-5
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>NIGHT TOUR: MARKET FOOD TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image009.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image010.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>16:00 – 20:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 55,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Transfer to Gwangjang Market → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Contents</label>
                <div class='text-gray-600 font-normal'>The Gwangjang Market is the nation's first market and continues to thrive as a popular tourist destination today. It’s comprised of 5,000 independent shops for clothing (Korean traditional costume, Hanbok), food and etc. Gwangjang Market is truly famous for its delicious Mung-bean pancake (Bindaetteok). All the prices are very affordable.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='full-day-tour-container'>
        <h1 class='mt-8 text-2xl font-semibold uppercase'>Full-day tours</h1>
        <table class='w-full mt-4 mb-16'>
          <colgroup>
            <col width="60px">
            <col width="*">
            <col width="120px">
            <col width="120px">
          </colgroup>
          <thead class=''>
            <tr class='border'>
              <th class='py-2 uppercase text-gray-600 text-sm text-left pl-2' colspan="2">Course</th>
              <th class='py-2 uppercase text-gray-600 text-sm text-center'>Time</th>
              <th class='py-2 uppercase text-gray-600 text-sm text-right pr-2 leading-5'>Price per Person</th>
            </tr>
          </thead>
          <tbody>
            <tr class='border-t border-l border-r'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-1</td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>DMZ Tour</h3>
              <p class='text-sm'>Meet at COEX→Transfer to DMZ (Circuit: DMZ Theater - The 3rd Infiltration Tunnel - Dora Observatory Dorasan Station)<br>→Back to COEX</p>
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>08:00 – 16:00 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 95,000</td>
            </tr>
            <tr class='border-t border-l border-r'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-2 </td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Museum &amp; Culture Tour</h3>
              <p class='text-sm'>Meet at COEX→National Museum of Korea→Namsangol Hanok Village→Namsan Tower Observatory→Back to COEX</p>  
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:00 – 17:00 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 109,000</td>
            </tr>
            <tr class='border-t border-l border-r'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-3 </td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Cultural Experience Tour</h3>
              <p class='text-sm'>Meet at COEX→Hanbok Wearing Experience→Gyeongbok Palace Visit→Bukchon Hanok Village<br>→Stamp Making Experience→Back to</p>
               COEX
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:00 – 17:00 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 175,000</td>
            </tr>
            <tr class='border-t border-l border-r'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-4 </td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Yongin Korean Folk Village &amp; Fortress Tour</h3>
              <p class='text-sm'>Meet at COEX→Yongin Korean Folk Village→Suwon Hwaseong Fortress→Back to COEX</p>  
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:00 – 17:00 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 140,000</td>
            </tr>
            <tr class='border-t border-l border-r'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-5 </td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Traces of BTS in Seoul’s Hot Places</h3>
              <p class='text-sm'>Meet at COEX→Gyeongbok Palace→National Museum of Korea→Korea House→Vinyl &amp; Plastic by Hyundai Card<br>→Back to COEX </p>
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:30 – 17:30 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 95,000</td>
            </tr>
            <tr class='border-t border-l border-r border-b'>
            <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>FD-6 </td>
            <td class='align-text-top py-2 px-2'>
              <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Are You a Big Fan of Bong Joon-ho Too? Go on a Bong Hive Tour Now!</h3>
              <p class='text-sm'>Meet at COEX→Korean Film Archive→Haneul Park→Lunch and Myeongdong→Hoehyeon Underground Shopping Center→Cafe Seoraesu→Back to COEX</p>  
            </td>
            <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>09:30 – 17:30 </td>
            <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 90,000</td>
            </tr>
          </tbody>
        </table>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            FD-1
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>DMZ Tour</span>
            <span class='inline-block pl-2 uppercase text-sm text-red-700 font-normal'>**Please bring your passport on this tour**</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image011.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image012.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>08:00 – 16:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 95,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee, SIC DMZ Tour</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Transfer to DMZ → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes. <i>No jeans and tank-tops.</i></div>
              </div>
            </div>
          </div>

          <div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image013.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>DMZ Theater</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>DMZ Theater is an interdisciplinary art project that imagines the 13 observatories located along the border of the Korean demilitarized zone (DMZ) as theaters and presents the narratives in the area as exhibitions and performances.</div>
              </div>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image014.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>The 3<sup>rd</sup> Infiltration Tunnel</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>The 3rd Tunnel was discovered by South Korea in 1978. It spans over 1,635m in length, 2m in width, and 2m in height and is located 52km from Seoul. It is estimated that approximately 30,000 soldiers could move through the tunnel per hour. Located only 4km away from Imjingak toward the southwest and 3.5km from the Tongilchon area, it is accessible by car in approximately 45 min from Seoul.</div>
            </div>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image015.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>Dorasan Observatory</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>Situated in Paju-si, Gyeonggi-do and at the northernmost point of the Military Demarcation Line, Dora Observatory replaced the previous Songaksan Observation Post. From the observatory, visitors can overlook North Korea, including Gaeseong, Songaksan, Kim Il-Sung Statue, and Cooperation Farm (Geumamgol). The observatory has 500 spectator seats, a VIP room, and a large parking area. It was first opened to the public in January 1987.</div>
            </div>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image016.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>Dorasan Station</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>Dorasan Station, a railway station on the Gyeongui Line, is the northernmost stop on South Korea's railway line. Located 55.8 kilometers from Seoul and 205 kilometers from Pyeongyang, the station was opened as a tourist attraction on April 4, 2002 right before the 2002 Korea-Japan World Cup.</div>
            </div>
          </div>

        </div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            FD-2
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>MUSEUM &amp; CULTURE TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image017.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image018.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:00 – 17:00 </div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 109,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → National Museum of Korea → Namsangol Hanok Village → Namsan Tower Observatory → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes. </div>
              </div>
            </div>
          </div>
          <div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
              <img src="https://apsr2022.org/img/sub/image019.png" class='block rounded'>
              <h3 class='font-semibold text-gray-800 uppercase mt-2'>National Museum of Korea</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>The National Museum of Korea houses a vast collection of over 220,000 artifacts from ancient times to the modern era in a wide range of topics, including art and culture. The museum houses a Children's Museum, where visitors can learn more about the nation's history through educational programs and experiences. The outdoor grounds feature pagodas and other stone artworks too large to be on display inside.</div>
            </div>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image020.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>Namsangol Hanok Village</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>Namsangol Hanok Village opened in 1998 on the northern side of Namsan Mountain in the center of the capital. This village has five restored hanok (traditional Korean house) premises, a pavilion, traditional garden, performance art stage and a time capsule plaza, making it a perfect spot for both locals and tourists to take a leisure walk. Upon entering from the front gate, visitors will get a taste of Korea's traditional life while escaping from the bustling city life. </div>
            </div>
            <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
              <div class='location-title'>
                <img src="https://apsr2022.org/img/sub/image021.png" class='block rounded'>
                <h3 class='font-semibold text-gray-800 uppercase mt-2'>Namsan Tower</h3>
              </div>
              <div class='detailed-description text-sm text-gray-700'>Namsan Seoul Tower was the first multipurpose tower to be established in Korea, effectively incorporating a sightseeing observatory to a broadcasting tower. For the past 40 years, Namsan Seoul Tower has served as an iconic landmark of Korea and a representative tourist attraction. The tower's observatory offers an unobstructed view of the whole city, allowing it to become one of the all-time favorite attractions of Seoul citizens as well as domestic and international tourists. Another highlight of the tower is the Namsan Cable Car.</div>
            </div>
            </div>
          </div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            FD-3
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>CULTURAL EXPERIENCE TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image022.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image023.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:00 – 17:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 175,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Hanbok Wearing Experience → Gyeongbok Palace → Bukchon Hanok Village<br> → Stamp Making Experience → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
            </div>
          </div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image024.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Hanbok Wearing Experience</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Hanbok is the traditional attire of the Korean people. Worn daily up until just 100 years ago, hanbok comes in various shapes and colors, reflecting the culture and lifestyle of the its time. Nowadays, it is only worn on special occasions or anniversaries. It is a formal wear and many Koreans keep a hanbok for such occasions.<br>
  The unique lines of hanbok appear at their greatest when the wearer is in motion. Hanbok is creative and expressive in its design. Another special feature about hanbok is the shape, having a slim top and wide bottom, similar to a bell.
</div>
</div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image025.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Gyeongbok Palace</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Built in 1395, Gyeongbokgung Palace is commonly referred to as the Northern Palace because its location is furthest north when compared to the neighboring palaces of Changdeokgung (Eastern Palace) and Gyeonghuigung (Western Palace). Gyeongbokgung Palace is arguably the most beautiful, and remains the largest of all five palaces. The premises were once destroyed by fire during the Imjin War (1592-1598). However, all of the palace buildings were later restored under the leadership of Heungseondaewongun during the reign of King Gojong (1852-1919).</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image026.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Bukchon Hanok Village</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Bukchon Hanok Village is a Korean traditional village in Seoul with a long history located on the top of a hill between Gyeongbok Palace, Changdeok Palace and Jongmyo Royal Shrine. The traditional village is composed of many alleys, hanok and is preserved to show a 600-year-old urban environment.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image027.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Stamp Making Experience</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Take part in the traditional practice of stamp engraving in Seoul, where you can put your own name or customize your logo onto a personalized seal! The perfect personal touch to decorate your letters or scrapbooks! You can personally customize the design, by hand carving your name and logo, when you engrave the stone yourself. Enjoy working with traditional tools and techniques during your seal engraving activity: from the art of designing the seal, calligraphy techniques, knife techniques and more. Traditional seal production in Seoul has a deep history, and you can experience it first hand. </div>
  </div>
</div>
</div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            FD-4
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>YONGIN CULTURE VILLAGE &amp; FORTRESS TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image028.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image029.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:00 – 17:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 140,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Yongin Korean Folk Village → Suwon Hwaseong Fortress → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image030.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Yongin Culture Village</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Korean Folk Village introduces traditional culture from the late Joseon period to both local and international visitors through cultural classes experience, shaman faith, seasonal customs and others. It recreated the past through the restoration of houses from the Joseon dynasty. Performances including nongak (farmer’s music), martial arts on horseback, traditional wedding ceremony, and other special events are available by season. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image031.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Suwon Hwaseong Fortress</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Suwon was one of four main regional government centers during the Joseon dynasty. Hwaseong Fortress, built to protect the city, was designated as a UNESCO World Cultural Heritage Site on December 12, 1997 for its historical value. The fortress offers various performances daily as well as the Suwon Hwaseong Cultural Festival every fall. The walls stretch for 5.7 kilometers, with Paldalsan Mountain at the center. The fortress, constructed from 1794 to 1796, was built as a display of King Jeongjo’s filial piety towards his father and to build a new pioneer city with its own economic power.</div>
  </div>
</div>
</div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            FD-5
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>TRACES OF BTS IN SEOUL'S HOT PLACES</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image032.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image033.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:00 – 17:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 95,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Gyeongbok Palace → National Museum of Korea → Korea House<br>→ Vinyl &amp; Plastic by Hyundai Card → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image034.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>National Museum of Korea</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The National Museum of Korea houses a vast collection of over 220,000 artifacts from ancient times to the modern era in a wide range of topics, including art and culture. The museum houses a Children's Museum, where visitors can learn more about the nation's history through educational programs and experiences. The outdoor grounds feature pagodas and other stone artworks too large to be on display inside.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image035.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Korea House</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Korea House is a restaurant and cultural venue that showcases the beauty of traditional Korean cuisine and culture. It is operated by the Korea Cultural Heritage Foundation, and was built on the site of the Joseon scholar Pak Paeng-Nyeon's private residence. The design was modeled on Gyeongbokgung Palace's Jagyeongjeon Hall.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image036.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Vinyl &amp; Plastic by Hyundai Card</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Vinyl &amp; Plastic is strategically positioned next to another of Hyundai Card’s cultural ventures – the Music Library, which houses over 10,000 records and some 3,000 music-related books. But unlike the Music Library – which is exclusively accessible only to those who possess a Hyundai Card – Vinyl &amp; Plastic is open to all.</div>
  </div>
</div>
</div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2 flex flex-row justify-start items-start gap-x-2'>
            <div class='' >FD-6</div>
            <div class='' >
              ARE YOU A BIG FAN OF BONG JOON-HO TOO?<br>
              <div :style='`${themeTextColorStyle}`'>GO ON A BONG HIVE TOUR NOW!</div>
            </div>
            
            
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
            <img src="https://apsr2022.org/img/sub/image037.png" class='block rounded'>
            <img src="https://apsr2022.org/img/sub/image038.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>09:00 – 17:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 90,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee, Lunch</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>Meet at COEX → Korean Film Archive → Haneul Park → Lunch and Myeongdong<br>→ Hoehyeon Underground Shopping Center → Cafe Seoraesu → Back to COEX</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image039.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Korean Film Archive</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The Korean Film Archive, run by the Korean Federation of Film Archives, is the only film museum in Seoul. This place is like a treasure chest where visitors can see and experience everything about Korean cinema. They can learn about the history of Korean cinema that spans over 100 years, from the birth of Korean films to the present where they become the wind beneath Hallyu (Korean Wave) all around the world. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image040.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Haneul Park</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The park was a filming location for "Memories of Murder," one of Director Bong's major works. In the movie, the park appears as a landfill where the detectives searched for evidence. Now, this park has been transformed into an ecological environment park and the bleak images seen in the scene are nowhere to be found. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image041.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Hoehyeon Underground Shopping Center</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Hoehyeon Underground Shopping Center is where Director Bong shot a scene in his first Netflix feature film "Okja," in which the superpig named Okja escapes. This shopping center has recently stepped into the spotlight again as a place full of analog vibes with the rise of retro or newtro trend. Here, many shops selling items that evoke nostalgia, such as LP records, film cameras, and antiques, are still in business</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image042.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Cafe Seoraesu</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Cafe Seoraesu, to which the entrance is covered in ivy, inviting you into the romantic space, is known as a cafe that Director Bong frequently visits. He sat by the window of this cafe and wrote the plot for the film “Parasite” and drew continuity sketches. The name "Seorae Su" means "the most outstanding thing from the West." It represents the owner's commitment to providing only the best coffee to customers. </div>
  </div>
</div>
</div>

</div>


      <div class="tour_con">
        <h1 class='mt-8 text-2xl font-semibold uppercase'>PRE &amp; POST TOUR</h1>
        <table class='w-full mt-4 mb-16'>
          <colgroup>
            <col width="60px">
            <col width="*">
            <col width="120px">
            <col width="120px">
          </colgroup>
          <thead class=''>
            <tr class='border'>
              <th class='py-2 uppercase text-gray-600 text-sm text-left pl-2' colspan="2">Course</th>
              <th class='py-2 uppercase text-gray-600 text-sm text-center'>Time</th>
              <th class='py-2 uppercase text-gray-600 text-sm text-right pr-2 leading-5'>Price per Person</th>
            </tr>
          </thead>
          <tbody>
            <tr class='border-t border-l border-r'>
              <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>PT-1</td>
              <td class='align-text-top py-2 px-2'>
                <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Gangneung Romantic Tour</h3>
                <p class='text-sm'>
                  <b>DAY 1: </b>Transfer to Gangneung→Jumunjin Breakwater→Ojukheon House→Terrarosa Coffee Museum<br>→Jungang Market→Hotel Check-in<br>
                  <b>DAY 2: </b>Hotel→Gangneung Unification Park→Unification Security Exhibition Hall→Jeongdong Simgok Sea Fan Road→Transfer to Seoul
                </p>
              </td>
              <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>
                DAY 1: 11:00 – 18:00<br>
                DAY 2: 09:00 – 16:00
              </td>
              <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 298,000</td>
            </tr>
            <tr class='border-t border-l border-r'>
              <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>PT-2</td>
              <td class='align-text-top py-2 px-2'>
                <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Gyeongju &amp; Busan Tour</h3>
                <p class='text-sm'>
                  <b>DAY 1: </b>Shin-Gyeongju Station→Bulguksa Temple→Seokguram Grotto→Anapji Pond→Hotel Check-in<br>
                  <b>DAY 2: </b>Hotel→Gyeongju National Museum→Daereungwon Tomb Complex→Cheomseongdae Observatory→Lunch→Travel to Busan→Haedong Yonggungsa Temple→Jagalchi Fish Market<br>→Gukje Market→Transfer to Hotel<br>
                  <b>DAY 3: </b>Hotel→Nurimaru APEC House→Taejeongdae Cliff→Lunch→Transfer to Busan Station
                </p>
              </td>
              <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>
                DAY 1: 13:00 – 20:00<br>
                DAY 2: 09:00 – 19:00<br>
                DAY 3: 09:00 – 15:00
              </td>
              <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 440,000</td>
              </tr>
            <tr class='border-t border-l border-r border-b'>
              <td class='align-text-top whitespace-no-wrap py-2 pl-2 font-semibold text-center'>PT-3</td>
              <td class='align-text-top py-2 px-2'>
                <h3 class='font-semibold capitalize'  :style='`${themeTextColorStyle}`'>Jeju (Most Beloved Island in Korea) Tour</h3>
                <p class='text-sm'>
                  <b>DAY 1: </b>Jeju Airport→Halim Park→Cheonjiyeon Falls→Jusangjeolli Cliff→Dinner→Hotel<br>
                  <b>DAY 2: </b>Hotel→Manjanggul Cave→Seopjikoji→Seongsan Ilchul Peak→Seongeup Folk Village→Dinner→Hotel<br>
                  <b>DAY 3: </b>Hotel→Halla Arboretum→Yongduam Rock→Dodubong→Transfer to Jeju Airport
                </p>
              </td>
              <td class='align-text-top whitespace-nowrap text-center py-2 text-sm lg:text-normal'>
                DAY 1: 13:00 – 20:00<br>
                DAY 2: 09:00 – 19:00<br>
                DAY 3: 09:00 – 12:00
              </td>
              <td class='align-text-top text-right pr-2  text-sm lg:text-normal'>KRW 389,000</td>
            </tr>
          </tbody>
        </table>


        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            PT-1
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>GANGNEUNG ROMANTIC TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image043.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image044.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>DAY 1: 11:00 – 18:00<br>DAY 2: 09:00 – 16:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 298,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee, Lunch Only</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>
                  <div>DAY 1: Transfer to Gangneung → Jumunjin Breakwater → Ojukheon House → Terrarosa Coffee Museum → Jungang Market → Hotel Check-in</div>
                  <div>DAY 2: Hotel → Gangneung Unification Park → Unification Security Exhibition Hall → Jeongdong Simgok Sea Fan Road → Transfer to Seoul</div>
                </div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 1</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image045.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jumunjin Break Water</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Jumunjin Breakwater is the largest harbor in the Donghae and it's crowded with fishermen and tourists on weekends. At the end of the breakwater, sushi restaurants are clustered to form a town. Also, it is famous for filming the drama "Goblin". You can take great photos with the sound of waves in the background. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image046.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Okjuheon House</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Ojukheon House is Korea's No. 165 treasure, where Shin Saimdang and Yulgok Yi were born. Since Saimdang was selected as the person who designed the currency, with the modifier of 'the birthplace of the world's first mother-and-child currency,' people from all over the world are increasing their visits. After visiting Ojukheon, there is also a city museum that shows the history and culture of Gangneung.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image047.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Terrarosa Coffee Museum</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Terraosa is a name that sounds familiar to everyone, even if he or she does not specialize in coffee. Terarosa actually started out as a coffee factory, so its interior design as a mix of elements of a warehouse and a coffee shop. The vintage items found throughout the shop also make the coffee taste extra special here. When you sit here, while sipping on coffee, the joy you experience through your taste buds and eyes will spread throughout your entire body.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image048.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jungang Market</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Gangwon-do’s representative traditional market, Jungang Market opened in 1980 as a permanent market. The market is divided into two main parts; one is a traditional market and the other is an underground fishery market. The second floor has a restaurant and coffee shops. Vendors display their products along the street, providing a typical scene of a traditional market and an opportunity to learn about the life of people in Gangneung.</div>
  </div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 2</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image049.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Gangneung Unification Park</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Gangneung Unification Part, located at Aninjin-ri, Gangdong-myeong, Gangneung-si, Gangwon-do, was opened in September 26, 2001. This park was designed to raise the national wish for reunification and national awareness of security after North Korean armed submarine’s penetration into the coast of Gangneung in September 18, 1996. As a military operation area, this is restricted from an access after 6 p.m. It is divided into the unification security hall and the warship exhibition hall, which are somewhat distant from each other. So, it would be better to use a car.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image050.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jeongdong Simgok Sea Fan Road</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Jeongdong Simgok Sea Fan Road is a coastal promenade where you can enjoy the beautiful seascape of Gangneung. It is designated as Natural Monument No. 437 and is the only coastal terrace in Korea where you can observe the tectonic changes 23 million years ago that keep the secret of the birth of the East Sea. An approximately 2.86km trail has been constructed, allowing visitors to appreciate the unexplored beauty of the blue waves of the East Sea and magnificent strange rocks. </div>
  </div>
</div>
</div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            PT-2
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>GYEONGJU &amp; BUSAN TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image051.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image052.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>
                  DAY 1: 13:00-20:00<br>
                  DAY 2: 09:00-19:00<br>
                  DAY 3: 09:00-15:00
                </div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 440,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee, Lunch Only</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>
                  <div>DAY 1: <span style="letter-spacing: -0.5px">Shin-Gyeongju Station → Bulguksa Temple → Seokguram Grotto → Anapji Pond → Hotel Check-in</span></div>
                  <div>DAY 2: Hotel → Gyeongju National Museum → Daereungwon Tomb Complex → Cheomseongdae Observatory → Lunch → Travel to Busan → Haedong Yonggungsa Temple → Jagalchi Fish Market<br> → Gukje Market → Transfer to Hotel</div>
                  <div>DAY 3: Hotel → Nurimaru APEC House → Taejeongdae Cliff → Lunch → Transfer to Busan Station</div>
                </div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 1</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image053.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Bulguksa Temple</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Bulguksa Temple is the representative relic of Gyeongju and was designated as a World Cultural Asset by UNESCO in 1995. The beauty of the temple itself and the artistic touch of the stone relics are known throughout the world.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image054.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Seokguram Grotto</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Seokguram, located on Tohamsan Mountain, is the representative stone temple of Korea. The official name of Seokguram, National Treasure No. 24, is Seokguram Seokgul. Designated as World Cultural Heritage Site by UNESCO in 1995, it is an artificial stone temple made of granite.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image055.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Anapji Pond</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Anapji Pond was built during the 14th year of King Munmu (in power 661-681 AD) of the Silla Kingdom (57 BC-935 AD). In 1974, an excavation project revealed large spherical shapes (measuring 200 meters in diameter and 180 meters in height) which indicated that 3 islands had been located in the pond.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image048.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jungang Market</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Gangwon-do’s representative traditional market, Jungang Market opened in 1980 as a permanent market. The market is divided into two main parts; one is a traditional market and the other is an underground fishery market. The second floor has a restaurant and coffee shops. Vendors display their products along the street, providing a typical scene of a traditional market and an opportunity to learn about the life of people in Gangneung.</div>
  </div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 2</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image056.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Gyeongju National Museum</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Gyeongju National Museum is loved by visitors and residents alike as it houses numerous historical and cultural artifacts of the Silla Dynasty (57 BC - AD 935).</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image057.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Daereungwon Tomb Complex</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The surrounding areas of Gyeongju might seem a little bit unique to the guests since there are many little mounts everywhere. Nevertheless, these little mounts are tombs and burial places of kings, queens and nobility personages. Your first visit to Gyeongju will be this unique tomb complex, the Daereungwon. Within Daereungwon Tomb Complex is Cheonmachong Tomb (Ancient Tomb No. 155), which was excavated in 1973. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image058.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Cheomseongdae Observatory</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The Cheomseongdae Observatory is the oldest astronomic observatory form Asia. The building was constructed with 362 stone bricks that represents the 362 days of a lunar year. Thanks to this observatory, many scholars were able to study the movement of the stars and to predict solar and lunar eclipses.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image059.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Haedong Yonggungsa Temple</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Haedong Yonggungsa Temple is situated on the coast of the north-eastern portion of Busan. This superb attraction offers visitors the rare find of a temple along the shore line since most temples in Korea are located in the mountains. Haedong Yonggungsa Temple was first built in 1376 by the great Buddhist teacher known as Naong during the Goryeo Dynasty. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image060.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jagalchi Fish Market</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Jagalchi Market, located on the shoreside road in Busan's Jung-gu, is Korea's largest seafood market, selling both live and dried fish. After the Korean War the market solidified itself as a fish market. Most of the people who sell fish are women, so the vendors here are called Jagalchi Ajumma, "ajumma" meaning middle-aged or married woman in Korean. This market represents Busan and is famous throughout the country</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/4 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image061.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Gukje Market</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Following the Korean War, refugees who fled to Busan set up stalls in order to make a living, and this was the beginning of the Gukje Market (gukje meaning "international" in Korean). The Gukje Market is one of Korea’s largest markets; each alleyway is crowded with stalls, and it connects to Bupyeong Market, Kkangtong Market, and other smaller markets. <br>
  The market stocks such a wide variety of goods, that you can find almost everything you need right here.
</div>
</div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 3</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image062.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Nurimaru APEC House</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The name Nurimaru APEC House is created by combining the Korean words nuri (“world”) and maru (“summit”), and “APEC House,” which refers to an APEC conference hall. Collectively, it means “a house where the world summits gather together for  an APEC meeting. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image063.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Taejeongdae Cliff</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Taejongdae Cliff is a natural park of Busan, South Korea with magnificent cliffs facing the open sea on the southernmost tip of island of Yeongdo-gu. It is a representative visitor attraction of Busan where has dense evergreen trees and several facilities for tourists such as an observatory, an amusement park, a light house, a cruise ship terminal. You can take Danubi train to get around Taejongdae Resort Park. </div>
  </div>
</div>
</div>

        <div class='my-16'>
          <h2 class='text-xl font-semibold text-gray-700 my-2'>
            PT-3
            <span class='inline-block pl-2' :style='`${themeTextColorStyle}`'>JEJU (MOST BELOVED ISLAND IN KOREA) TOUR</span>
          </h2>
          <div class='flex flex-col lg:flex-row justify-start items-start w-full gap-x-4'>
            <div class='w-full lg:w-2/5 flex-shrink-0 flex flex-col lg:gap-x-0 gap-y-2'>
              <img src="https://apsr2022.org/img/sub/image064.png" class='block rounded'>
              <img src="https://apsr2022.org/img/sub/image065.png" class='block rounded'>
            </div>
            <div class='mt-2 lg:mt-0'>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Date &amp; Time</label>
                <div class='text-gray-600 font-normal'>DAY 1: 13:00-20:00<br>DAY 2: 09:00-19:00<br>DAY 3: 09:00-12:00</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price per Person</label>
                <div class='text-gray-600 font-normal'>KRW 389,000</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Price included</label>
                <div class='text-gray-600 font-normal'>English-speaking Guide, Private Transfer, Admission Fee, Dinner Only</div>
              </div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Itinerary</label>
                <div class='text-gray-600 font-normal'>
              <div>DAY 1: Jeju Airport → Halim Park → Cheonjiyeon Falls → Jusangjeolli Cliff → Dinner → Hotel </div>
<div>DAY 2: Hotel → Manjanggul Cave → Seopjikoji → Seongsan Ilchul Peak → Seongeup Folk Village<br> → Dinner → Hotel</div>
<div>DAY 3: Hotel → Halla Arboretum → Yongduam Rock → Dodubong → Transfer to Jeju Airport</div>
</div>
</div>
              <div class='mb-4'>
                <label class='block uppercase text-sm text-gray-800 font-semibold'>Dress Code</label>
                <div class='text-gray-600 font-normal'>Dress casually and wear comfortable walking shoes.</div>
              </div>
</div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 1</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image066.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Hallim park</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Hallim Park is one of the most popular tourist spots on Jeju Island, located approximately 33 kilometers west of Jeju-si and Hallasan Mountain along the beach in Hallim-eup. It faces the pleasant scenery of Biyangdo Island, Hyeopjae Beach and Geumneung Beach. The park was established in early 1971 on barren land with the importation of tons of earth and the planting of assorted subtropical plants. Hallim Park, reaching almost 100 thousand square meters, has a variety of gardens that can be enjoyed in any season. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image067.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Cheonjiyeon Falls</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Cheonjeyeonpokpo Falls, named "The Pond of God," consists of three sections. The first waterfall falls into a pond with a depth of 21 meters. From here, it falls two more times, creating the second and third waterfalls, which then flow into the sea. To the east of the first waterfall is a cave, where water falls from the ceiling. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image068.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Jusangjeolli Cliff</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>If you follow the farm road about 600m to the southwest from Daepo-dong, at the end of the pine tree forest is a cliff. At the base of this cliff is the Jisatgae Coast along with the imposing stone pillars. Jusangjeolli are stone pillars piled up along the coast and is a designated cultural monument of Jeju Island. Jusangjeolli Cliff was formed when the lava from Hallasan Mountain erupted into the sea of Jungmun</div>
  </div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 2</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image069.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Manjanggul Cave</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Manjanggul Cave is one of the finest lava tunnels in the world, and is a designated natural monument. A lava tunnel is formed when the lava that was deep in the ground spouts from the peak and flows to the surface. Manjanggul Cave has a variety of interesting structures inside including 70cm lava stalagmites and the lava tube tunnels.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image070.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Seongsan Ilchul Peak</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Seongsan Ilchulbong Peak rose from under the sea in a volcanic eruption over 100,000 years ago. The crater on the top on the peak, surrounded by 99 sharp rocks, looks like a gigantic crown. The ridge, connecting the peak to the village in the north, provides an ideal spot for walks and for horse riding as well.</div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/3 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image071.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Seongeup Folk Village</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>The Seongeup Folk Village shows the unique culture of Jeju Island: the black lava rock walls, the straight but curvy alleys to block the wind, and the stone grandfather statues (Harubang), which have become a prime feature of the landscape</div>
  </div>
</div>
<div class='mt-4 font-semibold text-lg'>DAY 3</div>
<div class='tour-detailed-description flex flex-row flex-wrap justify-start mt-2'>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image072.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Halla Arboterum</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Halla Arboretum was established for nature research and preservation. Located near Gwangioreum Volcanic Cone, there are many kinds of native trees and subtropical plants exhibited here. The arboretum serves as a wonderful training resource for students and experts alike. It is also a popular themed attraction among tourists. There are 1,321 endangered and rare species that are preserved and over 100,000 plants on display. The arboretum serves not only as a tourist attraction, but also as an enjoyable and relaxing spot favorited by the locals of Jeju Island. </div>
  </div>
  <div class='flex flex-col justify-start items-center w-full lg:w-1/2 p-4 bg-gray-50'>
    <div class='location-title'>
      <img src="https://apsr2022.org/img/sub/image073.png" class='block rounded'>
      <h3 class='font-semibold text-gray-800 uppercase mt-2'>Dodubong</h3>
    </div>
    <div class='detailed-description text-sm text-gray-700'>Dodubong, located right next to Jeju International Airport and along Jeju Olle walking trail No. 17, this volcanic cone has a gentle incline and two peaks with forest and meadow. It only takes 20 minutes to walk to the top and enjoy the ocean view to the north and a panorama of Jeju City framed with magnificent Hallasan Mountain to the south</div>
  </div>
</div>
</div>

</div>
    </div>
  </div>
</template>

<script>
import HotelAndTourNavigation from '@/components/apsr2022/HotelAndTourNavigation.vue'
import WelcomeTitle           from '@/components/apsr2022/WelcomeTitle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Tour',
  components: {
    HotelAndTourNavigation,
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },
}
</script>
