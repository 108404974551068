<template>
  <div class='mb-16'>
    <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr_rainbow_border.png' class='h-1' />
    <h1
      class='mt-4 text-2xl font-semibold uppercase' 
      :style='themeTextColorStyle'>
      {{title}}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WelcomeTitle',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },
}
</script>
